<template>
  <section class="filter-wrap">
    <BasicFilterWrap
      :moreFilter="showMoreFilter"
      @confrim="onSearch"
      @reset="onResetFilter"
      @more="showMoreFilter = !showMoreFilter"
    >
      <el-form ref="form" :model="tableFilters" label-width="auto">
        <el-row :gutter="20">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="英文名称">
              <el-input
                v-model="tableFilters.nameEn"
                placeholder="请输入"
                clearable
                @keyup.enter.native="onSearch"
                @clear="onSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="中文名称">
              <el-input
                v-model="tableFilters.nameCn"
                placeholder="请输入"
                clearable
                @keyup.enter.native="onSearch"
                @clear="onSearch"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="材质">
              <BasicAsyncSelect
                multiple
                keyName="nameCn"
                valueName="id"
                v-model="tableFilters.materialsId"
                :asyncObj="{
                  dataFun: GetMosaicMaterialsList,
                }"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-collapse-transition>
          <el-row :gutter="10" v-if="showMoreFilter">
            <!-- <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="产品编号">
                <el-input
                  v-model="tableFilters.code"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="产品系列编号">
                <el-input
                  v-model="tableFilters.seriesCode"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="工艺">
                <BasicAsyncSelect
                  multiple
                  keyName="nameCn"
                  valueName="id"
                  v-model="tableFilters.techniquesId"
                  :asyncObj="{
                    dataFun: GetMosaicTechniquesList,
                  }"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="规格">
                <BasicAsyncSelect
                  multiple
                  keyName="nameCn"
                  valueName="id"
                  v-model="tableFilters.specificationsId"
                  :asyncObj="{
                    dataFun: GetMosaicSpecificationsList,
                  }"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="风格">
                <BasicAsyncSelect
                  multiple
                  keyName="nameCn"
                  valueName="id"
                  v-model="tableFilters.stylesId"
                  :asyncObj="{
                    dataFun: GetMosaicStylesList,
                  }"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="色系">
                <BasicAsyncSelect
                  multiple
                  keyName="nameCn"
                  valueName="id"
                  v-model="tableFilters.colorsId"
                  :asyncObj="{
                    dataFun: GetMosaicColorsList,
                  }"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="状态">
                <!-- <BasicSelect
                  class="basic-filter-item"
                  v-model="tableFilters.disabled"
                  :allOptions="_disableOptions"
                /> -->
                <el-radio-group v-model="tableFilters.disabled">
                  <el-radio :label="true">禁用</el-radio>
                  <el-radio :label="false">启用</el-radio>
                  <el-radio :label="null">全部</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="推荐产品">
                <el-radio-group v-model="tableFilters.star">
                  <el-radio :label="true">推荐产品</el-radio>
                  <el-radio :label="false">常规产品</el-radio>
                  <el-radio :label="null">全部</el-radio>
                </el-radio-group>
                <!-- <el-switch
                  v-model="tableFilters.star"
                  active-text="推荐产品"
                  inactive-text="常规产品"
                >
                </el-switch> -->
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="首页展示">
                <el-radio-group v-model="tableFilters.showOnIndex">
                  <el-radio :label="true">首页展示</el-radio>
                  <el-radio :label="false">非首页展示</el-radio>
                  <el-radio :label="null">全部</el-radio>
                </el-radio-group>
                <!-- <el-switch
                  v-model="tableFilters.showOnIndex"
                  active-text="首页展示"
                  inactive-text="非首页展示"
                >
                </el-switch> -->
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="创建日期">
                <el-date-picker
                  v-model="tableFilters.createdAt"
                  type="daterange"
                  style="width: 100%"
                  :default-time="['00:00:00', '23:59:59']"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="创建人">
                <BasicSelect
                  v-model="tableFilters.createdById"
                  :allOptions="_userOptions"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="更新日期">
                <el-date-picker
                  v-model="tableFilters.lastModifiedAt"
                  type="daterange"
                  style="width: 100%"
                  :default-time="['00:00:00', '23:59:59']"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="更新人">
                <BasicSelect
                  v-model="tableFilters.lastModifiedById"
                  :allOptions="_userOptions"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-collapse-transition>
      </el-form>
    </BasicFilterWrap>
  </section>
</template>

<script>
import BasicSelect from "@/components/BasicSelect";
import { onClearFilter } from "@/utils/table";
import BasicFilterWrap from "@/components/BasicFilterWrap";
import BasicAsyncSelect from "@/components/BasicAsyncSelect";
import { GetMosaicColorsList } from "@/views/productColors/api";
import { GetMosaicMaterialsList } from "@/views/productMaterials/api";
import { GetMosaicSpecificationsList } from "@/views/productSpecifications/api";
import { GetMosaicStylesList } from "@/views/productStyles/api";
import { GetMosaicTechniquesList } from "@/views/productTechniques/api";
export default {
  components: {
    BasicSelect,
    BasicFilterWrap,
    BasicAsyncSelect,
  },
  data() {
    return {
      showMoreFilter: false,
      tableFilters: {
        createdAt: [],
        createdById: null,
        lastModifiedAt: [],
        lastModifiedById: null,
        disabled: false,
        star: null,
        showOnIndex: null,
        nameEn: "",
        nameCn: "",
        materialsId: [],
        techniquesId: [],
        specificationsId: [],
        stylesId: [],
        colorsId: [],
      },

      propObj: {},
    };
  },

  computed: {
    _userOptions() {
      return this.$store.getters.userOptions;
    },
    _disableOptions() {
      return this.$store.getters.disableOptions;
    },
  },

  methods: {
    GetMosaicColorsList,
    GetMosaicMaterialsList,
    GetMosaicSpecificationsList,
    GetMosaicStylesList,
    GetMosaicTechniquesList,
    filterParams() {
      return this.tableFilters;
    },
    onSearch() {
      this.$emit("change", this.filterParams());
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.tableFilters.disabled = false;
      this.$emit("change", this.filterParams());
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
  margin: 10px 10px 0 10px;
  padding: 10px;
  background-color: #fff;
  ::v-deep {
    .el-form-item {
      .el-form-item__content {
        max-height: 40px;
      }

      overflow: hidden;
    }
  }
}
</style>
