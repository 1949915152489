<template>
  <section class="base-page">
    <PageHeader class="base-page-header base-shadow"> </PageHeader>
    <keep-alive><FilterSection @change="onFilterChange"/></keep-alive>
    <BasicTable
      showSelection
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <el-button
          size="small"
          class="mt-r"
          type="primary"
          icon="el-icon-document-add"
          @click="addRow"
          v-if="checkPermission(['PRODUCT_ADMIN'])"
          >新增</el-button
        >
        <el-checkbox class="mt-l mt-r" v-model="showDisable" @change="onSearch"
          >显示禁用</el-checkbox
        >
        &nbsp; &nbsp;
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'disabled'">
              <el-tag
                :type="scope.row.disabled ? 'danger' : 'success'"
                @click="toggleDisableRow(scope.row, scope.$index)"
                >{{ scope.row.disabled ? "已禁用" : "已启用" }}</el-tag
              >
            </span>
            <span v-else-if="column.prop === 'createdAt'">
              {{ scope.row.createdAt | date }}
            </span>
            <span v-else-if="column.prop === 'lastModifiedAt'">
              {{ scope.row.lastModifiedAt | date }}
            </span>

            <span v-else-if="column.prop === 'introduction'">
              <span v-html="scope.row.introduction"></span>
            </span>
            <span v-else-if="column.prop === 'posterImageUrl'">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.posterImageUrl"
                :preview-src-list="[scope.row.posterImageUrl]"
              >
              </el-image>
            </span>
            <span v-else-if="column.prop === 'coverImageUrl'">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.coverImageUrl"
                :preview-src-list="[scope.row.coverImageUrl]"
              >
              </el-image>
            </span>
            <span v-else-if="column.prop === 'renderingImageUrl'">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.renderingImageUrl"
                :preview-src-list="[scope.row.renderingImageUrl]"
              >
              </el-image>
            </span>
            <span v-else-if="column.prop === 'thumbnailUrl'">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.thumbnailUrl"
                :preview-src-list="[scope.row.thumbnailUrl]"
              >
              </el-image>
            </span>

            <span v-else-if="column.prop === 'materials'">
              <el-tag
                class="mt-r"
                size="small"
                v-for="t in scope.row.materials"
                :key="t.name"
                >{{ t.name }}</el-tag
              >
            </span>
            <span v-else-if="column.prop === 'techniques'">
              <el-tag
                class="mt-r"
                size="small"
                v-for="t in scope.row.techniques"
                :key="t.name"
                >{{ t.name }}</el-tag
              >
            </span>
            <span v-else-if="column.prop === 'specifications'">
              <el-tag
                class="mt-r"
                size="small"
                v-for="t in scope.row.specifications"
                :key="t.name"
                >{{ t.name }}</el-tag
              >
            </span>
            <span v-else-if="column.prop === 'styles'">
              <el-tag
                class="mt-r"
                size="small"
                v-for="t in scope.row.styles"
                :key="t.name"
                >{{ t.name }}</el-tag
              >
            </span>
            <span v-else-if="column.prop === 'colors'">
              <el-tag
                class="mt-r"
                size="small"
                v-for="t in scope.row.colors"
                :key="t.name"
                >{{ t.name }}</el-tag
              >
            </span>
            <span v-else-if="column.prop === 'series'">
              <el-tag
                class="mt-r"
                size="small"
                v-for="t in scope.row.series"
                :key="t.name"
                >{{ t.name }}</el-tag
              >
            </span>
            <span v-else-if="column.prop === 'heights'">
              <el-tag
                class="mt-r"
                size="small"
                v-for="t in scope.row.heights"
                :key="t.name"
                >{{ t.name }}</el-tag
              >
            </span>

            <span v-else-if="column.prop === 'showOnIndex'">
              <el-tag
                size="small"
                :type="scope.row.showOnIndex ? 'success' : ''"
                >{{ scope.row.showOnIndex ? "首页展示" : "非首页展示" }}</el-tag
              >
            </span>
            <span v-else-if="column.prop === 'star'">
              <el-tag size="small" :type="scope.row.star ? 'success' : ''">{{
                scope.row.star ? "推荐产品" : "常规产品"
              }}</el-tag>
            </span>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">

            <el-button
              v-if="scope.row.canUpdate"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              修改
            </el-button>

            <el-button
              class="text-danger"
              v-if="scope.row.canDisable"
              @click.native.prevent="toggleDisableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.disabled ? "启用" : "禁用" }}
            </el-button>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          class="mt-r"
          size="small"
          @click="onMultipleToggleDisable(false)"
          :disabled="multipleSelected.length <= 0"
          >批量启用 {{ multipleSelected.length }}</el-button
        >
        <el-button
          class="mt-r"
          size="small"
          @click="onMultipleToggleDisable(true)"
          :disabled="multipleSelected.length <= 0"
          >批量禁用 {{ multipleSelected.length }}</el-button
        >
      </template>
    </BasicTable>
    <el-drawer
      title="编辑"
      :visible.sync="formDialog"
      append-to-body
      direction="rtl"
      size="500px"
      ><Form :sid="currentId" @cancel="onFormCancel" @success="onFormSuccess"
    /></el-drawer>

    <el-drawer
      title="新增"
      :visible.sync="multipleFormDialog"
      append-to-body
      direction="rtl"
      size="50%"
    >
      <MultipleForm @cancel="onFormCancel" @success="onFormSuccess" />
    </el-drawer>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import {
  GetMosaicProductsList,
  ToogleDisableByIds,
  GetUpdateCheck,
  GetDiasbleCheck,
} from "./api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import {
  GetTableOrderParams,
  onClearFilter,
  FormatMultipleItemIds,
  GetTableDisableMessage,
} from "@/utils/table";
import Form from "./components/Form";
import MultipleForm from "./components/MultipleForm";
import PageHeader from "@/components/PageHeader";
import FilterSection from "./components/FilterSection";
import config from "@/config";
export default {
  components: {
    BasicTable,
    Form,
    FilterSection,
    MultipleForm,
    PageHeader,
  },
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      multipleFormDialog: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "seq",
      orderDirection: "descending",
      currentId: null,
      multipleSelected: [],
      showDisable: false,
      orderPropObj: {},
    };
  },
  computed: {
    images() {
      return this.tableData.map((item) => item.menuImageUrl);
    },
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        this.tableColumns = [
          {
            prop: "disabled",
            label: "状态",
            showColumn: true,
            width: 100,
            align: "center",
            fixed: "left",
            sortable: "custom",
          },
          {
            prop: "coverImageUrl",
            label: "封面图",
            showColumn: true,
            width: 100,
            align: "center",
            sortable: "custom",
          },

          {
            prop: "nameEn",
            label: "英文名",
            minWidth: 130,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "nameCn",
            label: "中文名",
            minWidth: 130,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          // {
          //   prop: "introduction",
          //   label: "简介",
          //   minWidth: 150,
          //   showColumn: true,
          //   align: "left",
          //   sortable: "custom",
          // },
          // {
          //   prop: "description",
          //   label: "图文详情",
          //   showColumn: true,
          //   align: "left",
          //   sortable: "custom",
          // },

          {
            prop: "materials",
            label: "材质",
            minWidth: 150,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "techniques",
            label: "工艺",
            minWidth: 150,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },

          {
            prop: "specifications",
            label: "规格",
            minWidth: 150,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "styles",
            label: "风格",
            minWidth: 150,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "colors",
            label: "色系",
            minWidth: 150,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "series",
            label: "系列",
            minWidth: 150,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "heights",
            label: "厚度",
            minWidth: 150,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          // {
          //   prop: "posterSlogan",
          //   label: "海报标语",
          //   width: 120,
          //   showColumn: true,
          //   align: "left",
          //   sortable: "custom",
          // },
          // {
          //   prop: "posterImageUrl",
          //   label: "海报图",
          //   showColumn: true,
          //   width: 100,
          //   align: "center",
          //   sortable: "custom",
          // },
          // {
          //   prop: "thumbnailUrl",
          //   label: "缩略图",
          //   showColumn: true,
          //   width: 100,
          //   align: "center",
          //   sortable: "custom",
          // },
          {
            prop: "code",
            label: "编号",
            minWidth: 80,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },

          {
            prop: "renderingImageUrl",
            label: "效果图",
            showColumn: false,
            width: 100,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "showOnIndex",
            label: "首页展示",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: false,
          },
          {
            prop: "star",
            label: "推荐产品",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: false,
          },
          {
            prop: "seq",
            label: "次序",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "id",
            label: "ID",
            width: 80,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },

          {
            prop: "createdByName",
            label: "创建人",
            width: 100,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建时间",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "lastModifiedByName",
            label: "更新人",
            width: 100,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "lastModifiedAt",
            label: "更新时间",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            align: "center",
            showColumn: true,
            label: "操作",
            width: 130,
          },
        ];
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },

    onSearch() {
      this.page = 0;
      this.getTableData();
    },
    getTableData() {
      this.tableLoading = true;
      GetMosaicProductsList({
        page: this.page,
        size: this.size,
        sort: GetTableOrderParams(
          this.orderProp,
          this.orderDirection,
          this.orderPropObj
        ),
        disabled: this.showDisable,
        ...this.tableFilters,
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.tableData = this.tableData.map((item) => {
            return {
              ...item,
              canUpdate: true,
              canDisable: true,
              isEdit: false,
            };
          });
          this.tableData.forEach((item) => {
            this.onCheckUpdate(item);
            this.onCheckDisbale(item);
          });
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.page = 0;
      this.onSearch();
    },
    onClearAndRefresh() {
      this.orderProp = "seq";
      this.orderDirection = "descending";
      this.onResetFilter();
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.page = 0;
      this.onRefresh();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "seq";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
    addRow() {
      this.$router.push({
        name: "ProductFormPage",
        params: {
          id: null,
        },
      });
    },
    editRow(row) {
      if (row.canUpdate) {
        this.$router.push({
          name: "ProductFormPage",
          params: {
            id: row.id,
          },
          query: {
            name: row.id,
          },
        });
       
      } else {
        this.$message.warning("没有权限执行此操作!");
      }
    },
    
    onCheckUpdate(item) {
      GetUpdateCheck(item.id)
        .then((res) => {
          item.canUpdate = res.data;
        })
        .catch(() => {
          item.canUpdate = true;
        });
    },
    onCheckDisbale(item) {
      GetDiasbleCheck([item.id])
        .then((res) => {
          item.canDisable = res.data;
        })
        .catch(() => {
          item.canDisable = true;
        });
    },
    onFormCancel() {
      this.currentId = null;
      this.multipleFormDialog = false;
      this.formDialog = false;
    },
    // 批量：启用/禁用
    onMultipleToggleDisable(flag = false) {
      if (this.multipleSelected.length <= 0) {
        return false;
      }
      let { ids, nameString, unableControl } = FormatMultipleItemIds({
        multipleSelectes: this.multipleSelected,
        nameKey: "nameCn",
      });
      let message = GetTableDisableMessage({
        total: this.multipleSelected.length,
        ableControlCount: ids.length,
        unableControlCount: unableControl.length,
        nameString: nameString,
        flag: flag,
        ableText: "启用",
        unAbleText: "禁用",
      });
      this.$msgbox({
        title: `${flag ? "禁用" : "启用"}提示`,
        message: message,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            if (ids.length <= 0) {
              this.$message.info("无有效数据可操作");
              done();
              return;
            }
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            ToogleDisableByIds(ids, flag)
              .then(() => {
                this.$message.success("操作成功");
                this.multipleSelected = [];
                this.onRefresh();
              })
              .catch((err) => {
                ShowApiError(err, "操作请求错误");
              })
              .finally(() => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
              });
          } else {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "";
            done();
          }
        },
      });
    },

    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    toggleDisableRow(row, index) {
      if (row.canDisable) {
        ToogleDisableByIds([row.id], !row.disabled).then(() => {
          row.disabled = !row.disabled;
          this.$set(this.tableData, index, row);
          this.$message.success("操作成功!");
        });
      } else {
        this.$message.warning("没有权限执行此操作!");
      }
    },

    onFormSuccess() {
      if (isEmprty(this.currentId)) {
        this.page = 0;
      }
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-image__inner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
